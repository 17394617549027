import React from "react";

import { Pannellum } from "pannellum-react";
import { useEffect } from "react";
import { useState } from "react";

const Panoramic = (props) => {
  const images = require(`../../assets/img/360/${props.room}.jpg`);

  return (
    <Pannellum
      width="100%"
      height="100%"
      image={images}
      pitch={10}
      yaw={180}
      hfov={110}
      showFullscreenCtrl={false}
      showZoomCtrl={false}
      disableKeyboardCtrl={true}
      autoLoad
      autoRotate={-1}
      onLoad={() => {
        console.log("panorama loaded");
      }}
    ></Pannellum>
  );
};

export default Panoramic;
