import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt, faSignOutAlt, faGlobeAsia, faChevronLeft, faDoorOpen, faDoorClosed } from '@fortawesome/free-solid-svg-icons'

const Sidebar = (props) => {

    const navigate = useNavigate();

    // componentDidMount() {
    //   document.getElementById('body').className = 'page-top';
    // }
    // state = {
    //   sidebarToggled: false,
    // }

    // handleSideBarToggle() {
    //   if (this.sidebarToogled === true) {
    //     this.setState({ sidebarToggled: !this.state.sidebarToggled });
    //     document.getElementById('body').className = 'page-top sidebar-toggled';
    //   } else {
    //     this.setState({ sidebarToggled: !this.state.sidebarToggled });
    //     document.getElementById('body').className = 'page-top';
    //   }

    // }

    const onHandleLogout = () => {
        toast.success('Successfuly logout...');
        sessionStorage.removeItem('users');

        setTimeout(() => {
            navigate('/admin/login');
        }, 1000);
    };

    const { clickMenuOpen, toggled } = true;
    return (
        <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-danger sidebar sidebar-dark accordion'} id="accordionSidebar" style={{ background: '#DD2727' }}>
            <wc-toast></wc-toast>
            {/* <!-- Sidebar - Brand --> */}
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/admin/dashboard">
                <div className="sidebar-brand-icon">
                    <FontAwesomeIcon icon={faGlobeAsia} color="#FFFFFF" style={{ fontSize: 24 }} />
                </div>
                <div className="sidebar-brand-text mx-3">Virtual Tour</div>
            </a>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0" style={{ backgroundColor: 'white' }} />

            {/* <!-- Nav Item - Dashboard --> */}
            <li className={props.activeSidebar == 'dashboard' ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/admin/dashboard">
                    <FontAwesomeIcon icon={faTachometerAlt} style={{ marginRight: 12 }} />
                    <span>Dashboard</span>
                </Link>
            </li>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider" style={{ backgroundColor: 'white' }} />
            <li className={props.activeSidebar == 'room' ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/admin/room">
                    <FontAwesomeIcon icon={faDoorOpen} style={{ marginRight: 12 }} />
                    <span>Room</span>
                </Link>
            </li>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider" style={{ backgroundColor: 'white' }} />

            <li className="nav-item">
                <div className="nav-link" onClick={() => onHandleLogout()} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 12 }} />
                    <span>Logout</span>
                </div>
            </li>

            {/* <!-- Sidebar Toggler (Sidebar) --> */}
            <div className="text-center d-none d-md-inline">
                <button onClick={() => { clickMenuOpen() }} className="rounded-circle border-0" id="sidebarToggle">
                    <FontAwesomeIcon icon={faChevronLeft} color='#FFFFFF' />
                </button>
            </div>
        </ul>)
};



export default Sidebar;