import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import { toast } from "wc-toast";
import { seo } from "../../../Components/Seo";

import { useSelector } from "react-redux";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";

//SBAdmin2 Style
import "../../../assets/css/sb-admin-2.css";

const Login = () => {
  const baseUrlApi = useSelector((state) => state.apiUrl);
  const token = useSelector((state) => state.token);

  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    seo({
      title: "Virtual Tour - Login",
    });
  }, []);

  const onSubmitLogin = (e) => {
    e.preventDefault();

    if (usernameValue == "") {
      toast.error("Email harus diisi.");
      return false;
    }

    if (passwordValue == "") {
      toast.error("Password harus diisi.");
      return false;
    }

    toast.promise(
      new Promise((resolve, reject) => {
        fetch(`${baseUrlApi}/login?token=${token}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `email=${usernameValue}&password=${passwordValue}`,
        })
          .then((response) => response.json())
          .then((results) => {
            if (results.code == 200) {
              sessionStorage.setItem("users", results.email);
              resolve(true);
              setTimeout(() => {
                navigate("/admin/dashboard");
              }, 1500);
            } else {
              console.log(results);
              reject(true);
            }
          });
      }),
      {
        loading: "Authenticating...",
        success: "Successfuly login",
        error: "Email or password invalid",
      }
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(https://psbb.id/solo/admin/img/solo.jpeg)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        position: "fixed",
        backgroundColor: "black",
        opacity: 0.9,
      }}
    >
      <wc-toast></wc-toast>
      <div className="container" style={{ height: 250 }}>
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Solo Technopark - Virtual Tour
                        </h1>
                      </div>
                      <form className="user" onSubmit={(e) => onSubmitLogin(e)}>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            placeholder="Enter Email Address..."
                            onChange={(e) => setUsernameValue(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            placeholder="Password"
                            onChange={(e) => setPasswordValue(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn-danger btn-user btn-block text-center"
                        >
                          Login
                        </button>
                      </form>
                      <hr />
                      <div className="text-center">
                        <a className="small" href="/">
                          Back To Virtual Tour
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
