const initialState = {
  music: false,
};

const initialToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZnVsbG5hbWUiOiJhYXJzYW5kaSIsInVzZXJuYW1lIjoiYWFyc2FuZGkiLCJlbWFpbCI6ImFhcnNhbmRpQGdtYWlsLmNvbSIsInR5cGUiOiJzdHVkZW50IiwiaWF0IjoxNjY0MDI2NDI0fQ.N_5Dt-YYq7boGemFSAMKrvZjP4CuRKsDRXild8Z38z4";

function reducer(state = initialState, action) {
  const { type, payload } = action;
  if (type === "SET_MUSIC") {
    return {
      music: payload,
    };
  }

  return state;
}

function token(state = initialToken, action) {
  const { type, payload } = action;
  if (type === "SET_TOKEN") {
    return payload;
  }

  return state;
}

export default reducer;
