import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
// Components
import Sidebar from "../../../Components/Admin/Sidebar";
import Topbar from "../../../Components/Admin/Topbar";
import { seo } from "../../../Components/Seo";

// Icons Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "../../../assets/css/formBanner.css";

import { useSelector } from "react-redux";

const Dashboard = () => {
  const baseUrlApi = useSelector((state) => state.apiUrl);
  const token = useSelector((state) => state.token);
  const sessionEmail = sessionStorage.getItem("users");

  let navigate = useNavigate();

  const [show, setShow] = useState(false);

  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!sessionEmail) {
      navigate("/admin/login");
    } else {
      fetch(`${baseUrlApi}/detail/${sessionEmail}?token=${token}`)
        .then((response) => response.json())
        .then((results) => {
          setUsers(results);
          seo({
            title: "Virtual Tour - Dashboard",
          });
        });
    }
  }, []);

  useEffect(() => {
    fetch(`${baseUrlApi}/banner?token=${token}`)
      .then((response) => response.json())
      .then((results) => {
        setData(results);
      });
  }, [data]);

  const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
  };

  const validateExtention = (extention) => {
    if (
      extention == "image/png" ||
      extention == "image/jpg" ||
      extention == "image/jpeg"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const previewImage = () => {
    const img = document.querySelector("#file-photos");
    const imgPreview = document.querySelector("#preview-photos");

    if (!validateExtention(img.files[0].type)) {
      toast.error("Please choose JPG, JPEG or PNG");
      img.value = "";
      return false;
    }

    const fileImg = new FileReader();
    fileImg.readAsDataURL(img.files[0]);

    fileImg.onload = function (e) {
      imgPreview.src = e.target.result;
    };
  };

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  function uploadImageAsPromise(imageFile) {
    return new Promise(function (resolve, reject) {
      const firebaseConfig = {
        apiKey: "AIzaSyAbDiylzDJ_ukXTyTYeq85-Usnkp85fW6o",
        authDomain: "solo-digital-tech.firebaseapp.com",
        projectId: "solo-digital-tech",
        storageBucket: "solo-digital-tech.appspot.com",
        messagingSenderId: "608688468148",
        appId: "1:608688468148:web:e503938ea2f4ea0eaa27e1",
        measurementId: "G-6GFS5NPL12",
      };

      // const firebaseConfig = {
      //   apiKey: "AIzaSyAx-0bTX81N6nfGB6Xbxjculclvil1B5Sg",
      //   authDomain: "ecommerce-a4bbb.firebaseapp.com",
      //   projectId: "ecommerce-a4bbb",
      //   storageBucket: "ecommerce-a4bbb.appspot.com",
      //   messagingSenderId: "450478453927",
      //   appId: "1:450478453927:web:65b39187a306e85c6c5782",
      //   measurementId: "G-V2J6599XLR",
      // };

      !firebase.apps.length
        ? firebase.initializeApp(firebaseConfig)
        : firebase.app();
      let storage = firebase
        .storage()
        .ref(
          "VirtualTour/banner/" +
            makeid(20) +
            "." +
            getFileExtension(imageFile.name)
        );
      let task = storage.put(imageFile);

      toast.promise(
        new Promise((resolve1, reject1) => {
          task.on(
            "state_change",
            function proggres(snap) {
              console.log((snap.bytesTransferred / snap.totalBytes) * 100);
            },
            function error(err) {
              console.log(err);
            },
            function completed(snap) {
              storage.getDownloadURL().then((url) => {
                resolve1(true);
                resolve(encodeURI(url));
              });
            }
          );
        }),
        {
          loading: "Loading...",
          success: "Successfuly added banner!",
          error: "failed!",
        }
      );
    });
  }

  const submitAddBanner = () => {
    const img = document.querySelector("#file-photos").files[0];
    const name = document.querySelector("#titlePhotos").value;
    const desc = document.querySelector("#descPhotos").value;

    if (img == undefined) {
      toast.error("Please choose image...");

      return false;
    }

    if (name == "") {
      toast.error("Title must be field...");

      return false;
    }

    const format = getFileExtension(img.name);

    (async function () {
      let result = await uploadImageAsPromise(img);

      fetch(`${baseUrlApi}/add-banner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `name=${name}&description=${desc}&img=${result}&format=${format}&token=${token}`,
      })
        .then((response) => response.json())
        .then((results) => {
          if (results.code == 200) {
            // resolve(true);
            setShow(false);
            console.log(results.code);
          } else {
            console.log(results);
            // reject(true);
          }
        });
    })();
  };

  const deleteBanner = (id) => {
    toast.promise(
      new Promise((resolve, reject) => {
        fetch(`${baseUrlApi}/delete-banner/${id}?token=${token}`)
          .then((response) => response.json())
          .then((results) => {
            resolve(true);
          });
      }),
      {
        loading: "Loading...",
        success: "Successfuly delete banner!",
        error: "failed!",
      }
    );
  };

  const { clickMenuOpen, toggled } = false;

  return (
    <div>
      <wc-toast></wc-toast>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar activeSidebar="dashboard" />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar username={users.username} />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}

              <div className="row"></div>

              <div className="row">
                <div className="d-flex justify-content-between px-0">
                  <div className="col-lg-2">
                    <h1 className="h3 mb-2 text-gray-800">Banner</h1>
                  </div>
                  <div className="col-lg-2 col-md-6 mb-3">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => setShow(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Banner
                    </button>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row mt-4">
                {data.map((results) => {
                  return (
                    <div className="col-lg-4 mb-2">
                      <div className="card card-body border-0 shadow">
                        <div className="align-items-center justify-content-center">
                          <a href="#">
                            <img
                              className="rounded avatar-xl"
                              src={results.img}
                              alt="change avatar"
                              style={{ height: 200, width: "100%" }}
                            />
                          </a>

                          <div
                            className="fw-normal text-dark mb-1 text-bold mt-3"
                            style={{ color: "#2B6AD0" }}
                          >
                            {results.name}
                          </div>
                          <div className="text-muted">
                            {results.description}
                          </div>
                        </div>

                        <hr style={{ background: "gray" }} />

                        <div className="row">
                          <div className="d-flex justify-content-between px-0">
                            <div className="col-lg text-start">
                              <div>
                                <a
                                  className="btn-danger btn-sm"
                                  style={{
                                    borderRadius: 50,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => deleteBanner(results.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color="#FFFFFF"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-lg-3 text-end">
                              <div
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                }}
                              >
                                {results.format}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2019</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="card card-body border-0 shadow mb-4">
                <h2 className="h5 mb-4">Select photo</h2>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      className="rounded avatar-xl"
                      src="https://portal.kartuku.co.id/assets/images/no-photos.jpg"
                      alt="change avatar"
                      width="100"
                      id="preview-photos"
                    />
                  </div>
                  <div className="file-field">
                    <div className="d-flex justify-content-xl-center ms-xl-3">
                      <div className="d-flex">
                        <svg
                          className="icon text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <input
                          type="file"
                          id="file-photos"
                          onChange={() => previewImage()}
                          accept="image/png, image/jpeg, image/jpg"
                        />
                        <div className="d-md-block text-left">
                          <div className="fw-normal text-dark mb-1">
                            Choose Image
                          </div>
                          <div className="text-gray small">
                            JPG, JPEG or PNG.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md mb-3">
            <div>
              <label>Title</label>
              <input
                className="form-control"
                id="titlePhotos"
                type="text"
                placeholder="Enter your title photo"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md mb-3">
            <div>
              <label>Description</label>
              <textarea
                name=""
                id="descPhotos"
                cols="3"
                rows="3"
                className="form-control"
                placeholder="Enter your description photo"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "inline" }}>
            <a
              className="badge badge-success mb-2"
              style={{
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
                cursor: "pointer",
                background: "#858796",
                fontSize: 14,
              }}
              onClick={() => setShow(false)}
            >
              Tutup
            </a>
          </div>
          <div style={{ display: "inline" }}>
            <a
              className="badge badge-success mb-2"
              style={{
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
                cursor: "pointer",
                background: "#DD2727",
                fontSize: 14,
              }}
              onClick={() => submitAddBanner()}
            >
              Submit
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
