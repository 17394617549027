import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// Screens
import Index from "./screens/index";
import Ecommerce from "./screens/Maps/Ecommerce";
import Etraining from "./screens/Maps/Etraining";
import Etalenthub from "./screens/Maps/Etalenthub";
import Login from "./screens/Admin/Login";
import Dashboard from "./screens/Admin/Dashboard";
import AdminRoom from "./screens/Admin/Room";
import Others from "./screens/Maps/Others";
import MusicPlayer from "./Components/MusicPlayer";

const Router = () => {
  const music = sessionStorage.getItem("music");

  return (
    <>
      <MusicPlayer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/maps/ecommerce" element={<Ecommerce />} />
          <Route path="/maps/etraining/:room" element={<Etraining />} />
          <Route path="/maps/etalenthub/:room" exact element={<Etalenthub />} />
          <Route path="/maps/others/:room" exact element={<Others />} />
          <Route path="/admin/login" exact element={<Login />} />
          <Route path="/admin/dashboard" exact element={<Dashboard />} />
          <Route path="/admin/room" exact element={<AdminRoom />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
