import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const Room = (props) => {
  const dataRoom = props.room;

  let navigate = useNavigate();
  const changeRoutes = (path) => {
    navigate(path);
    window.location.reload(false);
  };

  return (
    <>
      <BrowserView>
        <div className={props.style}>
          <div className="middle">
            <div className="innersRoom">
              <div className="row">
                {dataRoom.map((results) => {
                  return (
                    <div className="col-lg-4 mb-2">
                      <div
                        className="room"
                        onClick={() => changeRoutes(results.link)}
                      >
                        <div className="col-md-10">
                          <p className="white-text mb-0">{results.name}</p>
                        </div>
                        <div className="icon">
                          <FontAwesomeIcon icon={faCircleArrowRight} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className={props.style}>
          <div className="row" style={{ marginLeft: 2, marginRight: 2 }}>
            {dataRoom.map((results) => {
              return (
                <div className="col-6 mb-2">
                  <div
                    className="room"
                    onClick={() => changeRoutes(results.link)}
                  >
                    <div className="col-md-10">
                      <p className="white-text mb-0">{results.name}</p>
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCircleArrowRight} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MobileView>
    </>
  );
};

export default Room;
