import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
// Components
import Sidebar from "../../../Components/Sidebar";
import Panoramic from "../../../Components/Panoramic";
import Room from "../../../Components/Room";
import { seo } from "../../../Components/Seo";
import { Pannellum } from "pannellum-react";
// Icons Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

import { useSelector } from "react-redux";

// CSS
import "../../../assets/css/style.css";
import "../../../assets/css/main.css";

const Others = () => {
  const { room } = useParams();

  const baseUrlApi = useSelector((state) => state.apiUrl);
  const token = useSelector((state) => state.token);

  const [format, setFormat] = useState("");

  // const [styleRoom, setStyleRoom] = useState("container containers");
  const [styleRoom, setStyleRoom] = useState("outersRoom");
  const [styleOverlay, setStyleOverlay] = useState("color-overlay");
  const [activeLogo, setActiveLogo] = useState(true);

  const [asset, setAsset] = useState("");

  const [loading, setLoading] = useState(false);
  const [dataRoom, setDataRoom] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    fetch(`${baseUrlApi}/room?token=${token}`)
      .then((response) => response.json())
      .then((responses) => {
        setDataRoom(responses.reverse());
        // setDescription(responses[room].description);
        setLoading(true);

        seo({
          title: `Virtual Tour - Others Room - ${room}`,
        });
      });

    fetch(`${baseUrlApi}/detail-room/${room}?token=${token}`)
      .then((response) => response.json())
      .then((responses) => {
        setFormat(responses.format);
        setAsset(responses.file);
        setDescription(responses.description);
      });
  }, []);

  let navigate = useNavigate();

  const changeRoutes = (path) => {
    navigate(path);
    window.location.reload(false);
  };

  const onClickLogo = (value) => {
    setStyleRoom("outersRoom showRoom");
    setStyleOverlay("color-overlay " + value + "-overlay");
    setActiveLogo(false);
  };

  const onCloseRoom = () => {
    setStyleRoom("outersRoom");
    setStyleOverlay("color-overlay");
    setActiveLogo(true);
  };

  return (
    <div className={room == 11 ? "home" : "home cursor-panoramic"}>
      <Sidebar
        onClickLogo={() => onClickLogo("active")}
        activeLogo={activeLogo}
        onClose={() => onCloseRoom()}
      />

      {format == "mp4" ? (
        <video id="background-video" autoPlay muted>
          <source src={asset} type="video/mp4" />
          <source src={asset} type="video/ogg" />
        </video>
      ) : (
        <Pannellum
          width="100%"
          height="100%"
          image={asset}
          pitch={10}
          yaw={180}
          hfov={110}
          showFullscreenCtrl={false}
          showZoomCtrl={false}
          disableKeyboardCtrl={true}
          autoLoad
          onLoad={() => {
            console.log("panorama loaded");
          }}
        ></Pannellum>
      )}

      <div className={styleOverlay}></div>

      <div className="outer">
        <div className="middle">
          <div className="inner">
            <div className="menu-block" onClick={() => changeRoutes("/")}>
              <div style={{ textAlign: "center", padding: 12, marginTop: 12 }}>
                <FontAwesomeIcon
                  icon={faHome}
                  color="#FFFFFF"
                  style={{ fontSize: 18, marginBottom: 2 }}
                />
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontFamily: "NunitoNormal",
                  }}
                >
                  Home
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {description !== "" && (
        <div className="outers">
          <div className="middle">
            <div className="inners">
              <p className="desc">{description}</p>
            </div>
          </div>
        </div>
      )}

      {loading && <Room room={dataRoom} style={styleRoom} />}
    </div>
  );
};

export default Others;
