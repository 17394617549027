import { configureStore } from "@reduxjs/toolkit";
import reduxSlice from "./reduxSlice";
import reduxToken from "./reduxToken";
import reduxUrlApi from "./reduxUrlApi";

export default configureStore({
  reducer: {
    redux: reduxSlice,
    token: reduxToken,
    apiUrl: reduxUrlApi,
  },
});
