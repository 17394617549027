import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { toast } from "wc-toast";

// Components
import Sidebar from "../../../Components/Admin/Sidebar";
import Topbar from "../../../Components/Admin/Topbar";
import { seo } from "../../../Components/Seo";
import PlaceholderVideo from "../../../assets/img/video-placeholder.jpg";
// Icons Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { useSelector } from "react-redux";

const Room = () => {
  const baseUrlApi = useSelector((state) => state.apiUrl);
  const token = useSelector((state) => state.token);
  const sessionEmail = sessionStorage.getItem("users");

  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [inputEdit, setInputEdit] = useState("");
  const [description, setDescription] = useState("");
  const [idEdit, setIdEdit] = useState(0);

  const [show, setShow] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetch(`${baseUrlApi}/detail/${sessionEmail}?token=${token}`)
      .then((response) => response.json())
      .then((results) => {
        setUsers(results);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseUrlApi}/room?token=${token}`)
      .then((response) => response.json())
      .then((results) => {
        setData(results);
        seo({
          title: "Virtual Tour - Room",
        });
      });
  }, [data]);

  const { clickMenuOpen, toggled } = false;

  const handleClickEdit = (value, id, description) => {
    setShow(true);
    setInputEdit(value);
    setDescription(description);
    setIdEdit(id);
  };

  const handleClickSubmit = () => {
    fetch(`${baseUrlApi}/room-edit?token=${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `id=${idEdit}&name=${inputEdit}&description=${description}`,
    })
      .then((response) => response.json())
      .then((results) => {
        toast.success("Room berhasil diubah");
        setShow(false);
      });
  };

  const validateExtention = (extention) => {
    if (
      extention == "image/png" ||
      extention == "image/jpg" ||
      extention == "image/jpeg" ||
      extention == "video/mp4"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
  };

  const previewImage = () => {
    const img = document.querySelector("#file-photos");
    const imgPreview = document.querySelector("#preview-photos");

    if (!validateExtention(img.files[0].type)) {
      toast.error("Please choose JPG, JPEG PNG or MP4");
      img.value = "";
      return false;
    }

    let type = getFileExtension(img.files[0].name);

    if (type == "mp4") {
      imgPreview.src = PlaceholderVideo;
    } else {
      const fileImg = new FileReader();
      fileImg.readAsDataURL(img.files[0]);

      fileImg.onload = function (e) {
        imgPreview.src = e.target.result;
      };
    }
  };

  // Add Room

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  function uploadImageAsPromise(imageFile) {
    return new Promise(function (resolve, reject) {
      const firebaseConfig = {
        apiKey: "AIzaSyAbDiylzDJ_ukXTyTYeq85-Usnkp85fW6o",
        authDomain: "solo-digital-tech.firebaseapp.com",
        projectId: "solo-digital-tech",
        storageBucket: "solo-digital-tech.appspot.com",
        messagingSenderId: "608688468148",
        appId: "1:608688468148:web:e503938ea2f4ea0eaa27e1",
        measurementId: "G-6GFS5NPL12",
      };

      !firebase.apps.length
        ? firebase.initializeApp(firebaseConfig)
        : firebase.app();
      let storage = firebase
        .storage()
        .ref(
          "VirtualTour/room/" +
            makeid(20) +
            "." +
            getFileExtension(imageFile.name)
        );
      let task = storage.put(imageFile);

      toast.promise(
        new Promise((resolve1, reject1) => {
          task.on(
            "state_change",
            function proggres(snap) {
              console.log((snap.bytesTransferred / snap.totalBytes) * 100);
            },
            function error(err) {
              console.log(err);
            },
            function completed(snap) {
              storage.getDownloadURL().then((url) => {
                resolve1(true);
                // resolve(encodeURI(url));
                resolve(url);
                setShowModal(false);
              });
            }
          );
        }),
        {
          loading: "Loading...",
          success: "Successfuly added room!",
          error: "failed!",
        }
      );
    });
  }

  const submitAddRoom = () => {
    const img = document.querySelector("#file-photos").files[0];
    const name = document.querySelector("#titlePhotos").value;
    const desc = document.querySelector("#descPhotos").value;

    if (img == undefined) {
      toast.error("Please choose image / video...");

      return false;
    }

    if (name == "") {
      toast.error("Title must be field...");

      return false;
    }

    const format = getFileExtension(img.name);

    (async function () {
      if (format == "mp4") {
        var result = await uploadImageAsPromise(img);
      }

      const formData = new FormData();

      formData.append("name", name);
      formData.append("description", desc);
      formData.append("link", result);
      formData.append("format", format);

      if (format !== "mp4") {
        formData.append("file", img);
      }

      fetch(`${baseUrlApi}/add-room?token=${token}`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((results) => {
          setShowModal(false);
        });
    })();
  };

  const deleteRoom = (id) => {
    toast.promise(
      new Promise((resolve, reject) => {
        fetch(`${baseUrlApi}/delete-room/${id}?token=${token}`)
          .then((response) => response.json())
          .then((results) => {
            resolve(true);
          });
      }),
      {
        loading: "Loading...",
        success: "Successfuly delete room!",
        error: "failed!",
      }
    );
  };

  return (
    <div>
      <wc-toast></wc-toast>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <Sidebar activeSidebar="room" />
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar username={users.username} />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}

              <div className="row"></div>

              <div className="row">
                <div className="d-flex justify-content-between px-0">
                  <div className="col-lg-10">
                    <h1 className="h3 mb-2 text-gray-800">Room</h1>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => setShowModal(true)}
                      style={{ float: "right" }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Room
                    </button>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row mt-4">
                <div className="table-responsive">
                  <table
                    className="table table-striped table-hover"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead
                      style={{ backgroundColor: "#DD2727", color: "#FFFFFF" }}
                    >
                      <tr>
                        <th>Nama</th>
                        <th>Deskripsi</th>
                        <th>Pilihan</th>
                      </tr>
                    </thead>
                    <tbody id="column" className="content-table">
                      {data.map(function (row) {
                        return (
                          <tr>
                            <td>{row.name}</td>
                            <td>{row.description}</td>

                            <td>
                              <div className="row">
                                <div className="d-flex justify-content-between px-0">
                                  <div className="col-lg">
                                    <div>
                                      <a
                                        className="badge badge-success mb-2"
                                        style={{
                                          paddingLeft: 17,
                                          paddingRight: 17,
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          marginRight:
                                            row.editable == 1 ? -15 : 0,
                                          cursor: "pointer",
                                          float: "right",
                                        }}
                                        data-toggle="modal"
                                        data-target="#editModal"
                                        onClick={() =>
                                          handleClickEdit(
                                            row.name,
                                            row.id,
                                            row.description
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          color="#FFFFFF"
                                        />{" "}
                                        Ubah
                                      </a>
                                    </div>
                                  </div>
                                  {row.editable == 1 && (
                                    <div className="col-lg mb-3">
                                      <div>
                                        <a
                                          className="badge badge-danger mb-2"
                                          style={{
                                            paddingLeft: 12,
                                            paddingRight: 12,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          data-target="#editModal"
                                          onClick={() => deleteRoom(row.id)}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            color="#FFFFFF"
                                          />{" "}
                                          &nbsp; Hapus
                                        </a>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>

                            {/* <td>
                              <div>
                                <a
                                  className="badge badge-success mb-2"
                                  style={{
                                    paddingLeft: 17,
                                    paddingRight: 17,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    cursor: "pointer",
                                    float: "right",
                                  }}
                                  data-toggle="modal"
                                  data-target="#editModal"
                                  onClick={() =>
                                    handleClickEdit(
                                      row.name,
                                      row.id,
                                      row.description
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    color="#FFFFFF"
                                  />{" "}
                                  Ubah
                                </a>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2019</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ubah Nama</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group">
            <label>Nama</label>
            <input
              type="text"
              className="form-control"
              placeholder="Masukan nama..."
              autoFocus
              autoComplete="off"
              value={inputEdit}
              onChange={(e) => setInputEdit(e.target.value)}
            />
          </div>
          <div class="form-group">
            <label>Deskripsi</label>
            <textarea
              cols={5}
              rows={5}
              className="form-control"
              placeholder="Masukan deskripsi..."
              autoComplete="off"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "inline" }}>
            <a
              className="badge badge-success mb-2"
              style={{
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
                cursor: "pointer",
                background: "#858796",
                fontSize: 14,
              }}
              onClick={() => setShow(false)}
            >
              Tutup
            </a>
          </div>
          <div style={{ display: "inline" }}>
            <a
              className="badge badge-success mb-2"
              style={{
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
                cursor: "pointer",
                background: "#DD2727",
                fontSize: 14,
              }}
              onClick={() => handleClickSubmit()}
            >
              Ubah
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="card card-body border-0 shadow mb-4">
                <h2 className="h5 mb-4">Select photo / video</h2>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      className="rounded avatar-xl"
                      src="https://portal.kartuku.co.id/assets/images/no-photos.jpg"
                      alt="change avatar"
                      width="100"
                      id="preview-photos"
                    />
                  </div>
                  <div className="file-field">
                    <div className="d-flex justify-content-xl-center ms-xl-3">
                      <div className="d-flex">
                        <svg
                          className="icon text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <input
                          type="file"
                          id="file-photos"
                          onChange={previewImage}
                        />
                        <div className="d-md-block text-left">
                          <div className="fw-normal text-dark mb-1">
                            Choose Image / video
                          </div>
                          <div className="text-gray small">
                            JPG, JPEG, PNG or MP4.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md mb-3">
            <div>
              <label>Name</label>
              <input
                className="form-control"
                id="titlePhotos"
                type="text"
                placeholder="Enter your name room"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md mb-3">
            <div>
              <label>Description</label>
              <textarea
                name=""
                id="descPhotos"
                cols="3"
                rows="3"
                className="form-control"
                placeholder="Enter your description room"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "inline" }}>
            <a
              className="badge badge-success mb-2"
              style={{
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
                cursor: "pointer",
                background: "#858796",
                fontSize: 14,
              }}
              onClick={() => setShowModal(false)}
            >
              Tutup
            </a>
          </div>
          <div style={{ display: "inline" }}>
            <a
              className="badge badge-success mb-2"
              style={{
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
                cursor: "pointer",
                background: "#DD2727",
                fontSize: 14,
              }}
              onClick={() => submitAddRoom()}
            >
              Submit
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Room;
