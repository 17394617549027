import React, { useState, useEffect } from "react";
import Song from "../../assets/audio/backsound.mp3";
import { useSelector } from "react-redux";

const useAudio = () => {
  const music = useSelector((state) => state.redux.music);
  const [audio] = useState(new Audio(Song));
  const [playing, setPlaying] = useState(music);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    music ? audio.play() : audio.pause();
  }, [music]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [music, toggle];
};

const MusicPlayer = () => {
  const [playing, toggle] = useAudio();

  return null;
};

export default MusicPlayer;
