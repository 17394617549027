import React, { useState, useEffect } from "react";
// Icons Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faCircleArrowLeft,
  faVolumeHigh,
  faVolumeMute,
  faStore,
  faBook,
  faUserClock,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/img/logo-vt.svg";
import LogoVT from "../../assets/img/vt.png";
import LogoVector from "../../assets/img/Vector.png";

const Sidebar = (props) => {
  const [menu, setMenu] = useState("menu");
  const dispatch = useDispatch();
  const music = useSelector((state) => state.redux.music);

  const clickMenuBar = (value) => {
    setMenu(value);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const toggle = () => {
    dispatch({ type: "SET_MUSIC", payload: music ? false : true });
  };

  return (
    // <div style={{ zIndex: 10000000, backgroundColor: "red", height: 80 }}>
    // <div style={{ zIndex: 10000000 }}  >
    <div
      style={
        props.isMobile
          ? { zIndex: 10000000, backgroundColor: "#DD2727", height: 80 }
          : { zIndex: 10000000 }
      }
    >
      <div>
        {menu == "menu" && (
          <>
            <FontAwesomeIcon
              className="menu-bar"
              icon={faAlignLeft}
              onClick={() => clickMenuBar("menu active")}
            />
          </>
        )}

        {props.activeLogo ? (
          menu == "menu" && (
            <>
              {/* <div className="logo-button" onClick={props.onClickLogo}>
                <img src={LogoVector} className="logo-vector" />
                <img src={LogoVT} className="logo-vt" />
              </div> */}
            </>
          )
        ) : (
          <div className="logo-close" onClick={props.onClose}>
            <FontAwesomeIcon
              className="menu-bar"
              icon={faTimes}
              onClick={props.onClose}
            />
          </div>
        )}

        <div className="logo">
          <img src={Logo} className="logo-img" />
        </div>
      </div>

      <div className={menu}>
        <div>
          <FontAwesomeIcon
            className="menu-close"
            icon={faCircleArrowLeft}
            onClick={() => clickMenuBar("menu")}
          />
        </div>
        <div className="wrapper-menu">
          <div
            className="menus"
            onClick={() =>
              openInNewTab("https://virtual.solodigitaltechnopark.co.id")
            }
          >
            <div style={{ marginLeft: 20 }}>
              <p>
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: 12 }}
                  icon={faStore}
                  onClick={() => clickMenuBar("menu")}
                />{" "}
                Portal Virtual Tour
              </p>
            </div>
          </div>
          <div
            className="menus"
            onClick={() =>
              openInNewTab("https://ecommerce.solodigitaltechnopark.co.id/")
            }
          >
            <div style={{ marginLeft: 20 }}>
              <p>
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: 12 }}
                  icon={faStore}
                  onClick={() => clickMenuBar("menu")}
                />{" "}
                Portal eCommerce
              </p>
            </div>
          </div>
          <div
            className="menus"
            onClick={() =>
              openInNewTab("https://etraining.solodigitaltechnopark.co.id/")
            }
          >
            <div style={{ marginLeft: 20 }}>
              <p>
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: 12 }}
                  icon={faBook}
                  onClick={() => clickMenuBar("menu")}
                />{" "}
                Portal eTraining
              </p>
            </div>
          </div>
          <div
            className="menus"
            onClick={() =>
              openInNewTab("https://talenthub.solodigitaltechnopark.co.id/")
            }
          >
            <div style={{ marginLeft: 20 }}>
              <p>
                {" "}
                <FontAwesomeIcon
                  style={{ marginRight: 12 }}
                  icon={faUserClock}
                  onClick={() => clickMenuBar("menu")}
                />{" "}
                Portal Talenthub
              </p>
            </div>
          </div>
          {/* <div className="menus" onClick={toggle}>
            <div style={{ marginLeft: 20 }}>
              <p>
                <FontAwesomeIcon
                  style={{ marginRight: 12 }}
                  icon={music ? faVolumeHigh : faVolumeMute}
                />{" "}
                {music ? "Volume" : "Mute"}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
