import React, { useState, useEffect, useRef  } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Nav ,Navbar,Container, Toast, Overlay  } from 'react-bootstrap'
// Components
import Sidebar from "../Components/Sidebar";
import Room from "../Components/Room";
import { seo } from "../Components/Seo";

import { useSelector, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { BrowserView, MobileView } from "react-device-detect";

// Icons Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";

import ArticleImg from "../assets/img/article.webp";
import ArticleImg1 from "../assets/img/article-1.jpg";
import ArticleImg2 from "../assets/img/article-2.jpg";

import stpvirtual from "../assets/img/logo.png";
import mandiri from "../assets/img/logo-navbar-soloxlivin-etrain.svg";
import kawasan from "../assets/img/Kawsan-STP.png";

// CSS
import "../assets/css/style.css";
import "../assets/css/main.css";

//Homepage Component
const Index = () => {
  const baseUrlApi = useSelector((state) => state.apiUrl);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [once, setOnce] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataRoom, setDataRoom] = useState([]);
  const [music, setMusic] = useState(false);

  // useEffect(() => {
  //   document.getElementById("root").addEventListener(
  //     "click",
  //     function () {
  //       dispatch({ type: "SET_MUSIC", payload: true });
  //     },
  //     { once: true }
  //   );
  // }, []);

  useEffect(() => {
    fetch(`${baseUrlApi}/banner?token=${token}`)
      .then((response) => response.json())
      .then((results) => {
        setData(results);
        // setMusic(true);
        seo({
          title: "Virtual Tour - Home",
        });
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    fetch(`${baseUrlApi}/room?token=${token}`)
      .then((response) => response.json())
      .then((responses) => {
        setDataRoom(responses.reverse());
        // console.log(responses)
        setLoading(true);
      });
  }, []);

  const [styleRoom, setStyleRoom] = useState("outersRoom");
  const [styleOverlay, setStyleOverlay] = useState("color-overlay");
  const [activeLogo, setActiveLogo] = useState(true);

  let navigate = useNavigate();

  const changeRoutes = (path) => {
    navigate(path);
  };

  const onClickLogo = (value) => {
    setStyleRoom("outersRoom showRoom");
    setStyleOverlay("color-overlay " + value + "-overlay");
    setActiveLogo(false);
  };

  const onCloseRoom = () => {
    setStyleRoom("outersRoom");
    setStyleOverlay("color-overlay");
    setActiveLogo(true);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [room1, setRoom1] = useState(false);
  const target1 = useRef(null);
  const [room2, setRoom2] = useState(false);
  const target2 = useRef(null);
  const [room3, setRoom3] = useState(false);
  const target3 = useRef(null);
  const [room4, setRoom4] = useState(false);
  const target4 = useRef(null);
  const [room5, setRoom5] = useState(false);
  const target5 = useRef(null);
  const [room6, setRoom6] = useState(false);
  const target6 = useRef(null);
  const [room7, setRoom7] = useState(false);
  const target7 = useRef(null);
  const [room8, setRoom8] = useState(false);
  const target8 = useRef(null);
  const [room9, setRoom9] = useState(false);
  const target9 = useRef(null);
  const [room10, setRoom10] = useState(false);
  const target10 = useRef(null);
  const [room11, setRoom11] = useState(false);
  const target11 = useRef(null);
  const [room12, setRoom12] = useState(false);
  const target12 = useRef(null);
  const [room13, setRoom13] = useState(false);
  const target13 = useRef(null);
  const [room14, setRoom14] = useState(false);
  const target14 = useRef(null);
  const [room15, setRoom15] = useState(false);
  const target15 = useRef(null);

  return (
    <div
      className="home"
      id="home"
      style={{ position: "absolute", overflowX: "hidden" }}
    >
      <BrowserView>
        {/* <Sidebar
          onClickLogo={() => onClickLogo("active")}
          activeLogo={activeLogo}
          isMobile={false}
          onClose={() => onCloseRoom()}
        /> */}
      
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href="https://solodigitaltechnopark.co.id/"><img src={stpvirtual}></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link href="https://virtual.solodigitaltechnopark.co.id" target={"_blank"}>Portal Virtual Tour</Nav.Link>
                <Nav.Link href="https://ecommerce.solodigitaltechnopark.co.id/" target={"_blank"}>Portal eCommerce</Nav.Link>
                <Nav.Link href="https://etraining.solodigitaltechnopark.co.id/" target={"_blank"}>Portal eTraining</Nav.Link>
                <Nav.Link href="https://talenthub.solodigitaltechnopark.co.id/" target={"_blank"}>Portal Talenthub</Nav.Link>
                {/* <Button variant="danger" onClick={handleShow}>
                  Room
                </Button> */}
              </Nav>
            </Navbar.Collapse>
            <img src={mandiri} height='70'></img>
          </Container>
        </Navbar>

        <Modal show={show} onHide={handleClose} centered size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Kawasan STP</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{overflow: 'auto'}}>
              <div style={{backgroundImage: `url(${kawasan})`,
                  width: '100%',
                  height: '650px',
                  backgroundSize: 'cover',
                }}>
                  {dataRoom.map((results)=>{
                    if (results.id == 4) {
                      return(
                        <div style={{marginLeft: '440px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target4} onClick={() => setRoom4(!room4)}
                            style={{fontSize: '30px', color: 'red', marginTop: '275px', cursor: 'pointer'}}
                            />  
                            <Overlay target={target4.current} show={room4} placement="right">
                              {({ placement, arrowProps, room4: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>    
                        </div>
                 
                      );
                    }
                    if (results.id == 5) {
                      return(
                        <div style={{marginLeft: '420px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target5} onClick={() => setRoom5(!room5)}
                            style={{fontSize: '30px', color: 'red', marginTop: '315px', cursor: 'pointer'}}
                            />   
                            <Overlay target={target5.current} show={room5} placement="right">
                              {({ placement, arrowProps, room5: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>     
                        </div>
                 
                      );
                    }
                    
                    
                    if (results.id == 2) {
                      return(
                        <div style={{marginLeft: '500px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target2} onClick={() => setRoom2(!room2)}
                            style={{fontSize: '15px', color: 'red', marginTop: '270px', cursor: 'pointer'}}
                            />  
                            <Overlay target={target2.current} show={room2} placement="right">
                              {({ placement, arrowProps, room2: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>              
                        </div>                 
                      );
                    }
                    if (results.id == 3) {
                      return(
                        <div style={{marginLeft: '535px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target3} onClick={() => setRoom3(!room3)}
                            style={{fontSize: '15px', color: 'red', marginTop: '270px', cursor: 'pointer'}}
                            />
                            <Overlay target={target3.current} show={room3} placement="right">
                              {({ placement, arrowProps, room3: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>              
                        </div>                 
                      );
                    }
                    if (results.id == 6) {
                      return(
                        <div style={{marginLeft: '570px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target6} onClick={() => setRoom6(!room6)}
                            style={{fontSize: '15px', color: 'red', marginTop: '280px', cursor: 'pointer'}}
                            />   
                            <Overlay target={target6.current} show={room6} placement="right">
                              {({ placement, arrowProps, room6: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>            
                        </div>                 
                      );
                    }

                    if (results.id == 7) {
                      return(
                        <div style={{marginLeft: '510px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target7} onClick={() => setRoom7(!room7)}
                            style={{fontSize: '15px', color: 'red', marginTop: '300px', cursor: 'pointer'}}
                            /> 
                            <Overlay target={target7.current} show={room7} placement="right">
                              {({ placement, arrowProps, room7: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>            
                        </div>                 
                      );
                    }
                    if (results.id == 8) {
                      return(
                        <div style={{marginLeft: '540px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target8} onClick={() => setRoom8(!room8)}
                            style={{fontSize: '15px', color: 'red', marginTop: '300px', cursor: 'pointer'}}
                            />  
                            <Overlay target={target8.current} show={room8} placement="right">
                              {({ placement, arrowProps, room8: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>             
                        </div>                 
                      );
                    }
                    if (results.id == 9) {
                      return(
                        <div style={{marginLeft: '575px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target9} onClick={() => setRoom9(!room9)}
                            style={{fontSize: '15px', color: 'red', marginTop: '300px', cursor: 'pointer'}}
                            />   
                            <Overlay target={target9.current} show={room9} placement="right">
                              {({ placement, arrowProps, room9: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>          
                        </div>                 
                      );
                    }
                    if (results.id == 10) {
                      return(
                        <div style={{marginLeft: '585px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target10} onClick={() => setRoom10(!room10)}
                            style={{fontSize: '15px', color: 'red', marginTop: '325px', cursor: 'pointer'}}
                            /> 
                            <Overlay target={target10.current} show={room10} placement="right">
                              {({ placement, arrowProps, room10: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>        
                        </div>                 
                      );
                    }
                    if (results.id == 11) {
                      return(
                        <div style={{marginLeft: '515px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target11} onClick={() => setRoom11(!room11)}
                            style={{fontSize: '15px', color: 'red', marginTop: '325px', cursor: 'pointer'}}
                            />   
                            <Overlay target={target11.current} show={room11} placement="right">
                              {({ placement, arrowProps, room11: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>          
                        </div>                 
                      );
                    }
                    if (results.id == 13) {
                      return(
                        <div style={{marginLeft: '550px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target13} onClick={() => setRoom13(!room13)}
                            style={{fontSize: '15px', color: 'red', marginTop: '325px', cursor: 'pointer'}}
                            /> 
                            <Overlay target={target13.current} show={room13} placement="right">
                              {({ placement, arrowProps, room13: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>              
                        </div>                 
                      );
                    }


                    if (results.id == 15) {
                      return(
                        <div style={{marginLeft: '470px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target15} onClick={() => setRoom15(!room15)}
                            style={{fontSize: '25px', color: 'red', marginTop: '200px', cursor: 'pointer'}}
                            />    
                            <Overlay target={target15.current} show={room15} placement="right">
                              {({ placement, arrowProps, room15: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>    
                        </div>
                 
                      );
                    }
                    if (results.id == 1) {
                      return(
                        <div style={{marginLeft: '530px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target1} onClick={() => setRoom1(!room1)}
                            style={{fontSize: '20px', color: 'red', marginTop: '175px', cursor: 'pointer'}}
                            />    
                            <Overlay target={target1.current} show={room1} placement="right">
                              {({ placement, arrowProps, room1: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>     
                        </div>
                 
                      );
                    }
                    if (results.id == 12) {
                      return(
                        <div style={{marginLeft: '560px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target12} onClick={() => setRoom12(!room12)}
                            style={{fontSize: '20px', color: 'red', marginTop: '220px', cursor: 'pointer'}}
                            />
                            <Overlay target={target12.current} show={room12} placement="right">
                              {({ placement, arrowProps, room12: _show, popper, ...props }) => (
                                <div
                                onClick={() => changeRoutes(results.link)}
                                  {...props}
                                  style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                    padding: '2px 10px',
                                    color: 'white',
                                    borderRadius: 3,
                                    zIndex: 9999,
                                    cursor: 'pointer',
                                    ...props.style,
                                  }}
                                >
                                  {results.name}
                                </div>
                              )}
                            </Overlay>       
                        </div>
                 
                      );
                    }
                    if (results.id == 14) {
                      return(
                        <div style={{marginLeft: '620px', position: 'absolute'}}>
                          <FontAwesomeIcon
                            icon={faMapPin}
                            ref={target14} onClick={() => setRoom14(!room14)}
                            style={{fontSize: '25px', color: 'red', marginTop: '180px', cursor: 'pointer'}}
                            /> <Overlay target={target14.current} show={room14} placement="right">
                            {({ placement, arrowProps, room14: _show, popper, ...props }) => (
                              <div
                              onClick={() => changeRoutes(results.link)}
                                {...props}
                                style={{
                                  position: 'absolute',
                                  backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                  padding: '2px 10px',
                                  color: 'white',
                                  borderRadius: 3,
                                  zIndex: 9999,
                                  cursor: 'pointer',
                                  ...props.style,
                                }}
                              >
                                {results.name}
                              </div>
                            )}
                          </Overlay>        
                        </div>
                 
                      );
                    }
                    
                    
                                                    
                  })}                  
              </div>
         </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Tutup
            </Button>
          </Modal.Footer>
        </Modal>


        <div className="carousel-wrapper">
          <Carousel
            infiniteLoop
            useKeyboardArrows
            autoPlay
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            interval={2000}
          >
            {data.length > 0
              ? data.map((results, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={results.img}
                        style={{
                          height: "100vh",
                          width: "100%",
                          marginBottom: 50,
                        }}
                        key={index}
                        alt={results.img}
                      />
                    </div>
                  );
                })
              : null}
          </Carousel>
        </div>

        {data.length > 0 && (
          <div className="row">
            <div class="col-lg-4 col-md-6 mt-3">
              <article class="bg-white d-flex flex-column h-100">
                <div class="post-image">
                  <a
                    class="d-block"
                    href="https://solotechnopark.wixsite.com/site"
                    title="What Do You Do When You Know You’re Not Fine?"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      class="px-3"
                      src={ArticleImg}
                      alt="What Do You Do When You Know You’re Not Fine?"
                      style={{ width: "100%", height: 300, objectFit: "cover" }}
                    />
                  </a>
                </div>
                <div class="p-4 pb-0">
                  <div class="position-relative">
                    <h3
                      class="h4 post-title mb-2 line-clamp clamp-2"
                      style={{
                        color: "#DD2727",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open("https://solotechnopark.wixsite.com/site")
                      }
                    >
                      Selamat Datang di Solo TechnoPark
                    </h3>
                    <p class="mb-0 line-clamp clamp-3">
                      Solo Technopark adalah sebuah pusat vokasi dan inovasi
                      teknologi di Kota Surakarta, yang dibangun dari sinergi
                      dan hubungan yang kokoh antar dunia pendidikan, bisnis dan
                      pemerintah (Bapeda, 2009).
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-lg-4 col-md-6 mt-3">
              <article class="bg-white d-flex flex-column h-100">
                <div class="post-image">
                  <a
                    class="d-block"
                    href="https://surakarta.go.id/?p=25039"
                    title="What Do You Do When You Know You’re Not Fine?"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      class="px-3"
                      src={ArticleImg1}
                      alt="What Do You Do When You Know You’re Not Fine?"
                      style={{ width: "100%", height: 300, objectFit: "cover" }}
                    />
                  </a>
                </div>
                <div class="p-4 pb-0">
                  <div class="position-relative">
                    <h3
                      class="h4 post-title mb-2 line-clamp clamp-2"
                      style={{
                        color: "#DD2727",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open("https://surakarta.go.id/?p=25039")
                      }
                    >
                      Co-Working Space di Solo Technopark
                    </h3>
                    <p class="mb-0 line-clamp clamp-3">
                      Solo Technopark (STP) merupakan kawasan terpadu di bawah
                      pengelolaan Pemerintah Kota Surakarta, yang merupakan
                      kawasan berbasis Ilmu Pengetahuan dan Teknologi (IPTEK).
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-lg-4 col-md-6 mt-3">
              <article class="bg-white d-flex flex-column h-100">
                <div class="post-image">
                  <a
                    class="d-block"
                    href="https://www.instagram.com/p/CcFkSc_hu_k/?igshid=YmMyMTA2M2Y"
                    title="What Do You Do When You Know You’re Not Fine?"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      class="px-3"
                      src={ArticleImg2}
                      style={{
                        width: "100%",
                        height: 300,
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
                <div class="p-4 pb-0">
                  <div class="position-relative">
                    <h3
                      class="h4 post-title mb-2 line-clamp clamp-2"
                      style={{
                        color: "#DD2727",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/p/CcFkSc_hu_k/?igshid=YmMyMTA2M2Y"
                        )
                      }
                    >
                      GET TO KNOW SOLO TECHNOPARK
                    </h3>
                    <p class="mb-0 line-clamp clamp-3">
                      Apa kabar, Techno Fellas! Gimana puasa hari ke-6, masih
                      kuat kann?
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        )}
      </BrowserView>

      <MobileView>
        <Sidebar
          onClickLogo={() => onClickLogo("active")}
          activeLogo={activeLogo}
          isMobile={true}
          onClose={() => onCloseRoom()}
        />
        <div className="carousel-wrapper" style={{ marginBottom: 12 }}>
          <Carousel
            infiniteLoop
            useKeyboardArrows
            autoPlay
            showIndicators={true}
            showStatus={false}
            showThumbs={false}
            interval={4000}
            showArrows
            emulateTouch
          >
            {data.length > 0
              ? data.map((results, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={results.img}
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          marginBottom: 50,
                          marginTop: 12,
                        }}
                        key={index}
                        alt={results.img}
                      />
                    </div>
                  );
                })
              : null}
          </Carousel>
        </div>

        {data.length > 0 && (
          <div className="row">
            <div class="col-lg-4 col-md-6 ">
              <article class="bg-white d-flex flex-column h-100">
                <div class="post-image">
                  <a
                    class="d-block"
                    href="https://solotechnopark.wixsite.com/site"
                    title="What Do You Do When You Know You’re Not Fine?"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      class="w-100 h-auto px-3"
                      src={ArticleImg}
                      alt="What Do You Do When You Know You’re Not Fine?"
                      width="400"
                      height="264"
                    />
                  </a>
                </div>
                <div class="p-4 pb-0">
                  <div class="position-relative">
                    <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                      <a
                        class="text-link stretched-link"
                        href="https://solotechnopark.wixsite.com/site"
                        title="Read more about - What Do You Do When You Know You’re Not Fine?"
                        target="_blank"
                      >
                        Selamat Datang di Solo TechnoPark
                      </a>
                    </h3>
                    <p class="mb-0 line-clamp clamp-3">
                      Solo Technopark adalah sebuah pusat vokasi dan inovasi
                      teknologi di Kota Surakarta, yang dibangun dari sinergi
                      dan hubungan yang kokoh antar dunia pendidikan, bisnis dan
                      pemerintah (Bapeda, 2009).
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-lg-4 col-md-6 mt-3">
              <article class="bg-white d-flex flex-column h-100">
                <div class="post-image">
                  <a
                    class="d-block"
                    href="https://surakarta.go.id/?p=25039"
                    title="What Do You Do When You Know You’re Not Fine?"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      class="w-100 h-auto px-3"
                      src={ArticleImg1}
                      alt="What Do You Do When You Know You’re Not Fine?"
                      width="400"
                      height="264"
                    />
                  </a>
                </div>
                <div class="p-4 pb-0">
                  <div class="position-relative">
                    <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                      <a
                        class="text-link stretched-link"
                        href="https://surakarta.go.id/?p=25039"
                        title="Read more about - What Do You Do When You Know You’re Not Fine?"
                        target="_blank"
                      >
                        Co-Working Space di Solo Technopark
                      </a>
                    </h3>
                    <p class="mb-0 line-clamp clamp-3">
                      Solo Technopark (STP) merupakan kawasan terpadu di bawah
                      pengelolaan Pemerintah Kota Surakarta, yang merupakan
                      kawasan berbasis Ilmu Pengetahuan dan Teknologi (IPTEK).
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="col-lg-4 col-md-6 mt-3">
              <article class="bg-white d-flex flex-column h-100">
                <div class="post-image">
                  <a
                    class="d-block"
                    href="https://www.instagram.com/p/CcFkSc_hu_k/?igshid=YmMyMTA2M2Y"
                    title="What Do You Do When You Know You’re Not Fine?"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      class="w-100 h-auto px-3"
                      src={ArticleImg2}
                      width="400"
                      height="264"
                    />
                  </a>
                </div>
                <div class="p-4 pb-0">
                  <div class="position-relative">
                    <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                      <a
                        class="text-link stretched-link"
                        href="https://www.instagram.com/p/CcFkSc_hu_k/?igshid=YmMyMTA2M2Y"
                        title="Read more about - What Do You Do When You Know You’re Not Fine?"
                        target="_blank"
                      >
                        GET TO KNOW SOLO TECHNOPARK
                      </a>
                    </h3>
                    <p class="mb-0 line-clamp clamp-3">
                      Apa kabar, Techno Fellas! Gimana puasa hari ke-6, masih
                      kuat kann?
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        )}
      </MobileView>

      {/* <div className="outer">
        <div className="middle">
          <div className="inner">
            <div className="menu-block">
              <div style={{ textAlign: "center", padding: 12, marginTop: 12 }}>
                <FontAwesomeIcon
                  icon={faHome}
                  color="#FFFFFF"
                  style={{ fontSize: 18, marginBottom: 2 }}
                />
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontFamily: "NunitoNormal",
                  }}
                >
                  Home
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className={styleOverlay}></div>

      {loading && <Room room={dataRoom} style={styleRoom} />}
    </div>
    
  );
};

export default Index;
