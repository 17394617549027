const initialUrlApi = "https://apivirtualtour.solodigitaltechnopark.co.id";

function urlApi(state = initialUrlApi, action) {
  const { type, payload } = action;
  if (type === "SET_URL") {
    return payload;
  }

  return state;
}

export default urlApi;
